<template>
  <base-section
    id="jazz"
    class="pt-4"
  >
    <v-tabs
      v-model="tab"
      fixed-tabs
    >
      <v-tab @click="clearFilters">
        Discover Music
      </v-tab>
      <v-tab @click="clearFilters">
        Music Trails
      </v-tab>
      <v-tab @click="clearFilters">
        Band Finder
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless show-arrows>
      <v-tab-item>
        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="7"
            >
              <base-subheading
                size="display-1"
                title="Jazz Weekend"
                space="0"
              />

              <base-title
                class="primary--text"
                title="Discover the music"
                tag="div"
              />

              <v-divider
                class="my-8"
              />

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <span class="mr-3 text--subtitle-1">Day</span>
                </v-col><v-col
                  cols="12"
                  md="6"
                >
                  <v-btn-toggle
                    v-model="filters.day"
                    mandatory
                  >
                    <v-btn
                      v-for="item in days"
                      :key="item.name"
                      :class="[
                        filters.day == item.value ? 'primary white--text' : '',
                      ]"
                    >
                      {{ item.name }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <span class="mr-3 text--subtitle-1">Time</span>
                </v-col><v-col
                  cols="12"
                  md="6"
                >
                  <v-btn-toggle
                    v-model="filters.time"
                    mandatory
                  >
                    <v-btn
                      v-for="item in times"
                      :key="item.value"
                      :class="[
                        filters.time == item.value ? 'primary white--text' : '',
                      ]"
                    >
                      {{ item.name }}
                    </v-btn>
                  </v-btn-toggle>
                  <div
                  v-show="filters.time != 3"
                  class="text-caption ml-2"
                >{{ helperText }}</div>
                </v-col>
              </v-row>
              <v-row v-if="filters.time == 2">
                <v-spacer />
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="filters.selectedTime"
                    :items="getGigTimes"
                    class="mt-3"
                    outlined
                    clearable
                    item-text="name"
                    return-object
                    label="Time"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <span class="mr-3 text--subtitle-1">Dj</span>
                </v-col><v-col>
                  <v-btn-toggle
                    v-model="filters.dj"
                    mandatory
                  >
                    <v-btn
                      v-for="item in options"
                      :key="item.name"
                      :class="[
                        filters.dj == item.value ? 'primary white--text' : '',
                      ]"
                    >
                      {{ item.name }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <span class="mr-3 text--subtitle-1">Venue</span>
                </v-col>
                <v-col>
                  <v-select
                    v-model="filters.selectedVenue"
                    class="mt-3"
                    outlined
                    clearable
                    item-text="name"
                    return-object
                    label="Venue"
                    :items="getVenueList"
                  >
                    <template v-slot:item="{ item }">
                      {{ item.name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <base-btn
                    class="mt-2"
                    :color="!theme.isDark ? 'accent' : 'white'"
                    outlined
                    :small="true"
                    @click="(search = false) & applyFilters(null)"
                  >
                    Clear
                  </base-btn>
                </v-col>
                <v-col>
                  <base-btn
                    id="search"
                    class="mt-2"
                    :small="true"
                    @click="(search = true) & applyFilters(filters)"
                  >
                    Search
                  </base-btn>
                </v-col>
              </v-row>
              <v-divider
                class="my-8"
              />
              <transition name="fade">
                <v-row
                  v-if="getFilteredGigs.length > 0"
                  id="filteredGigs"
                >
                  <v-col
                    v-for="(gig, i) in getFilteredGigs"
                    :key="i"
                    cols="12"
                  >
                    <div
                      style="cursor: pointer"
                      @click="showDetail(gig)"
                    >
                      {{ computeDate(gig.date) }} {{ gig.time }} -
                      <b>{{ getPubName(gig.pub) }}</b> {{ gig.act }}
                    </div>
                    <div v-if="selectedGig == gig">
                      <v-row>
                        <v-col>
                          <div>
                            <pub-detail-map
                              :pub="selectedPub"
                              :highlight="false"
                              :fixed-width="true"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </transition>
              <transition name="fade">
                <v-row
                  v-if="(getFilteredGigs.length === 0) & search"
                  align="center"
                >
                  <v-col>
                    <div class="mr-3 text--subtitle-1 d-flex align-center">
                      No music matches your selections
                    </div>
                  </v-col>
                </v-row>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="7"
            >
              <base-subheading
                size="display-1"
                title="Jazz Weekend"
                space="0"
              />

              <base-title
                class="primary--text"
                title="Follow the Music"
                tag="div"
              />
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <span class="mr-3 text--subtitle-1">Day</span>
                </v-col><v-col
                  cols="12"
                  md="6"
                >
                  <v-btn-toggle
                    v-model="filters.day"
                    mandatory
                  >
                    <v-btn
                      v-for="item in days"
                      :key="item.name"
                      :class="[
                        filters.day == item.value ? 'primary white--text' : '',
                      ]"
                    >
                      {{ item.name }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <v-select
                v-model="selectedTrail"
                class="mt-3"
                outlined
                clearable
                return-object
                label="Select a trail"
                :items="filteredTrails"
                @input="trailSelected()"
              >
                <template v-slot:item="{ item }">
                  {{ item.text }}
                </template>
              </v-select>
              <base-map
                ref="map"
                :large-map="true"
              />
              <trail-detail-card
                v-if="getSelectedTrail"
                id="trailDetailCard"
                :seasonal-trails="true"
                @pubSelected="selectPub"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="7"
            >
              <base-subheading
                size="display-1"
                title="Jazz Weekend"
                space="0"
              />

              <base-title
                class="primary--text"
                title="Find your Music"
                tag="div"
              />
              <v-combobox
                v-model="selectedAct"
                class="mt-3"
                outlined
                clearable
                return-object
                label="Search for band"
                hide-selected
                :items="getGigActs"
              />
            </v-col>
          </v-row>
          <v-divider
            class="my-8"
          />
          <transition name="fade">
            <v-row
              v-if="selectedAct"
              id="filteredGigs"
            >
              <v-col
                v-for="(gig, i) in filteredActGigs"
                :key="i"
                cols="12"
              >
                <div
                  style="cursor: pointer"
                  @click="showDetail(gig)"
                >
                  {{ computeDate(gig.date) }} {{ gig.time }} -
                  <b>{{ getPubName(gig.pub) }}</b> {{ gig.act }}
                </div>
                <div v-if="selectedGig === gig">
                  <v-row>
                    <v-col>
                      <div>
                        <pub-detail-map
                          :pub="selectedPub"
                          :highlight="false"
                          :fixed-width="true"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </transition>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </base-section>
</template>

<script>
  import BaseMap from '../../components/map/BaseMap.vue'
  import TrailDetailCard from '../../components/TrailDetailCard.vue'
  import PubDetailMap from '../../components/map/PubDetailMap.vue'
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'SectionJazz',
    inject: ['theme'],
    components: { BaseMap, TrailDetailCard, PubDetailMap },
    data: () => ({
      card: {
        title: 'Find a Gig',
        outlined: true,
      },
      options: [
        { value: 0, name: 'Yes' },
        { value: 1, name: 'No' },
        { value: 2, name: "Don't Mind" },
      ],
      days: [
        { value: 0, name: 'Thurs' },
        { value: 1, name: 'Fri' },
        { value: 2, name: 'Sat' },
        { value: 3, name: 'Sun' },
        { value: 4, name: 'Mon' },
        { value: 5, name: 'All' },
      ],
      times: [
        { value: 0, name: 'Now' },
        { value: 1, name: 'Next' },
        { value: 2, name: 'Specific' },
        { value: 3, name: 'Any' },
      ],
      filters: {
        day: 5,
        time: 3,
        dj: 2,
        selectedVenue: null,
        selectedTime: null,
      },
      selectedTrail: null,
      tab: 0,
      selectedGig: null,
      selectedPub: null,
      selectedAct: null,
      displayClock: false,
      search: false,
    }),
    computed: {
      ...mapGetters([
        'getSeasonalTrails',
        'getSelectedTrail',
        'getFilteredGigs',
        'getPubList',
        'getVenueList',
        'getGigTimes',
        'getGigActs',
        'getGigList',
      ]),
      filteredActGigs () {
        return this.getGigList.filter(gig => this.selectedAct === gig.act)
      },
      filteredTrails () {
        if (this.filters.day === 5) {
          return this.getSeasonalTrails
        } else {
          return this.getSeasonalTrails.filter(trail => trail.day === this.filters.day)
        }
      },
      helperText () {
        if (this.filters.time === 0) {
          return 'Music that started within the last hour'
        } else if (this.filters.time === 1) {
          return 'Gigs that will start less than an hour from now'
        } else if(this.filters.time === 2) {
          return 'Pick a time that suits you'
        } else {
          return ''
        }
      },
    },
    mounted () {
      this.clearFilters()
    },
    methods: {
      ...mapActions(['updateSelectedTrail', 'filterGigs']),
      clearFilters () {
        this.updateSelectedTrail(null)
        this.filterGigs(null)
        this.search = false
      },
      applyFilters (filters) {
        if (filters === null) {
          this.filters = {
            day: 5,
            time: 3,
            dj: 2,
            selectedVenue: null,
            selectedTime: null,
          }
        }
        this.filterGigs(filters)
        if (filters) {
          this.$nextTick(function () {
            document
              .getElementById('search')
              .scrollIntoView({ behavior: 'smooth', block: 'start' })
          })
        }
      },
      showDetail (gig) {
        if (this.selectedGig === gig) {
          this.selectedGig = null
        } else {
          this.selectedGig = gig
        }
        if (this.selectedPub && this.selectedPub.id === gig.pub) {
          this.selectedPub = null
        } else {
          this.selectedPub = this.getPubList.find((pub) => pub.id === gig.pub)
        }
      },
      trailSelected () {
        this.updateSelectedTrail(this.selectedTrail)
        if (this.selectedTrail) {
          this.$nextTick(function () {
            document
              .getElementById('trailDetailCard')
              .scrollIntoView({ behavior: 'smooth', block: 'end' })
          })
        }
      },
      computeDate (date) {
        if (date === '24/10/2024') {
          return 'Thurs'
        } else if (date === '25/10/2024') {
          return 'Fri'
        } else if (date === '26/10/2024') {
          return 'Sat'
        } else if (date === '27/10/2024') {
          return 'Sun'
        } else if (date === '28/10/2024') {
          return 'Mon'
        }
      },
      getPubName (id) {
        console.log(id)
        return this.getPubList.find((pub) => pub.id === id).name
      },
      selectPub (pub) {
        this.$refs.map.selectPub(pub)
      },
    },
  }
</script>

<style scoped>
@import "https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css";
.fade-enter-active {
  transition: opacity 2s ease;
}
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
